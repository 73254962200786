import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import '../styles/MenuPage.css';

const MenuPage = () => {
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    const fetchMeals = async () => {
      try {
        const response = await fetch('http://localhost:5000/get-meals'); // Adjust the URL accordingly
        if (!response.ok) {
          throw new Error('Failed to fetch meals data');
        }
        const data = await response.json();
        setMeals(data.meals);
      } catch (error) {
        console.error('Error fetching meals data:', error);
      }
    };

    fetchMeals();
  }, []);

  return (
    <div>
      <div className="title">MENU</div>
      <ul className="meals-list">
        {meals.map((meal, index) => (
          <li key={index}>{meal}</li>
        ))}
      </ul>
      <div className="navigation">
        <BackButton to="/survey" text="Back to Survey" />
      </div>
    </div>
  );
};

const BackButton = ({ to, text }) => {
  return (
    <Link to={to} className="back button">
      {text}
    </Link>
  );
};

export default MenuPage;
