import { React, useState } from 'react'
import '../styles/GroceryList.css'
import { Link } from "react-router-dom"
import logo from '../images/prepandplate1.png'


const GroceryList = () => {
  // populate the nagivation links at the bottom
  const leftLink = "/PlannerPage"
  const rightLink = "#"
    // items to be fetched?
  const items = ["Sugar", "Milk", "Butter", "Orange juice"]

  const [selectedItems, setSelectedItems] = useState([])

  const genPdf = () => {

  }

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
        setSelectedItems([...selectedItems, value])
    } else { // unchecked, so remove it
        setSelectedItems( prev => {
            prev.filter(item => item !== value)
        })
    }
    console.log(selectedItems)
  }

  return (
    <div className='center-container'>
      <div  className='hor-container'>
        <div className='hor-item hor-left'><button onClick={genPdf}>PDF</button></div>
        <div className='hor-item hor-mid title'><center>Grocery List</center></div>
        <div className='hor-item hor-right'><img width='100' src={logo} alt="Logo"/></div>
      </div>
      <div className='hor-item hor-mid'>
        {items.map((item) => (            
          <div key={item}>
            <label className="survey-checkbox-label" htmlFor={item}>
                <input type='checkbox' name={item} value={item} id={item.toLowerCase().replace(" ", "_")}
                    checked={ selectedItems && selectedItems.includes(item) }
                    onChange={handleCheckboxChange}
                />&nbsp;
                {item}
            </label>
          </div>
        ))}

      </div>
      <div className='hor-container'>
        <div className='hor-item hor-left'><a className='back button' href="#">&lt; Weekly Planner</a></div>
        <div className='hor-item hor-right'><a className='next button' href="#">Recipe &gt;</a></div>
        
      </div>
    </div>
  )
}

export default GroceryList
